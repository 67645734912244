import { render, staticRenderFns } from "./DiskSnapshots.vue?vue&type=template&id=38583d04&scoped=true"
import script from "./DiskSnapshots.vue?vue&type=script&lang=js"
export * from "./DiskSnapshots.vue?vue&type=script&lang=js"
import style0 from "./DiskSnapshots.vue?vue&type=style&index=0&id=38583d04&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38583d04",
  null
  
)

/* custom blocks */
import block0 from "./DiskSnapshots.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports