<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <page-block class="vps-detail-view__block">
        <tariffs-table-snapshots :dataset="tariff" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import TariffsTableSnapshots from '@/layouts/Stack/pages/Main/components/TariffsTableSnapshots';
export default {
  name: 'DiskSnapshots',
  components: {
    TariffsTableSnapshots,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    instance() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverId) || '';
    },
    snapshots() {
      return this.$store.state.moduleStack.snapshots.filter(x => x.volume_id === this.diskId);
    },
    diskId() {
      return this.tariff.id;
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "attach": "Подключен",
      "size": "Размер",
      "type": "Тип диска",
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
